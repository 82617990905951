<style scoped>
a {
    cursor: pointer;
}
</style>

<template>
    <div>
        <div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
            <span>当前位置：</span>
            课程管理
            <span>&gt;</span>
            设置课程《{{ course.title }}》的推广
            <button class="veadmin-iframe-button" onclick="history.back();">
                <i class="el-icon-back"></i>
            </button>
            <button class="veadmin-iframe-button" onclick="location.reload()">
                <i class="el-icon-refresh-right"></i>
            </button>
        </div>
        <div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
            <div style="padding: 20px">
                <el-steps :active="3" finish-status="success" align-center style="margin-bottom: 10px">
                    <el-step title="设置课程基本信息" description="已完成"></el-step>
                    <el-step title="设置课程标签" description="已完成"></el-step>
                    <el-step title="设置发布平台" description="已完成"></el-step>
                    <el-step title="设置推广" description="进行中..."></el-step>
                </el-steps>
                <div style="padding: 10px 0px">
                    <el-button type="primary" icon="el-icon-plus" @click="showAddCourseAd()">新增发布平台</el-button>
                    <el-button @click="jumpTo('/admin/course/list')">完成设置</el-button>
                </div>
                <el-card class="box-card" ref="newCourseAd" style="display: none">
                    <div slot="header" class="clearfix">
                        <span>新增推广</span>
                    </div>
                    <div class="text item">
                        <el-form ref="elForm" :model="newCourseAd" status-icon :rules="rules" label-width="100px" style="margin: 20px">
                            <el-form-item label="推广标题" prop="title">
                                <el-input v-model="newCourseAd.title" placeholder="请填写推广标题"></el-input>
                            </el-form-item>
                            <el-form-item label="推广链接" prop="adUrl">
                                <el-input v-model="newCourseAd.adUrl" placeholder="请填写推广链接"></el-input>
                            </el-form-item>
                            <el-button type="primary" style="margin-left: 100px; width: 200px" @click="addCourseAd()">保存</el-button>
                        </el-form>
                    </div>
                </el-card>
                <el-card class="box-card" style="margin: 10px 0px" v-for="courseAd in courseAdList" :key="courseAd.id">
                    <div slot="header" class="clearfix">
                        <span>推广</span>
                        <el-button
                            style="float: right; margin-top: -10px"
                            type="danger"
                            icon="el-icon-delete"
                            plain
                            @click="deleteCourseAd(courseAd.id)"
                            >删除</el-button
                        >
                    </div>
                    <div class="text item">
                        <el-descriptions class="margin-top" :column="4">
                            <el-descriptions-item label="推广标题">
                                {{ courseAd.title }}
                            </el-descriptions-item>
                            <el-descriptions-item label="推广链接">
                                <a @click="openNewWindow(courseAd.ad_url)">{{ courseAd.ad_url }}</a>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import { apiListCourseAdByCourseId } from "@/api/public/courseAd.js";
import { apiAddCourseAd, apiDeleteCourseAdById } from "@/api/admin/courseAd.js";
export default {
    data() {
        var checkEmpty = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("不能为空"));
            } else {
                callback();
            }
        };
        return {
            course: {
                id: this.$route.query.courseId,
                title: this.$route.query.courseTitle
            },
            newCourseAd: {
                courseId: this.$route.query.courseId,
                title: "",
                adUrl: ""
            },
            rules: {
                title: [{ validator: checkEmpty, trigger: "blur" }],
                adUrl: [{ validator: checkEmpty, trigger: "blur" }]
            },
            courseAdList: []
        };
    },
    methods: {
        async listCourseAdByCourseId() {
            try {
                const response = await apiListCourseAdByCourseId(this.course.id);
                if (response.data.code == 200) {
                    this.courseAdList = response.data.data;
                    if (this.courseAdList.length > 0) {
                        this.$refs.newCourseAd.$el.style.display = "none";
                    } else {
                        this.$refs.newCourseAd.$el.style.display = "block";
                    }
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.log(error);
                this.$message.error(error);
            }
        },
        addCourseAd() {
            //去除首尾空格
            this.newCourseAd.title = this.newCourseAd.title.trim();
            this.newCourseAd.adUrl = this.newCourseAd.adUrl.trim();

            var formData = new FormData();
            formData.append("courseId", this.newCourseAd.courseId);
            formData.append("title", this.newCourseAd.title);
            formData.append("adUrl", this.newCourseAd.adUrl);

            //调用接口 新增课程发布平台
            apiAddCourseAd(this.$store.state.token, formData).then((response) => {
                if (response.data.code === 200) {
                    this.$message({
                        message: "保存成功！",
                        type: "success"
                    });
                    //执行成功1秒后刷新页面
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                } else {
                    console.log("response>>", response);
                    this.$message.error(response.data.message);
                }
            });
        },
        async deleteCourseAd(id) {
            let formData = new FormData();
            formData.append("token", this.$store.state.token);
            formData.append("id", id);

            //调用接口
            const response = await apiDeleteCourseAdById(formData);
            if (response.data.code === 200) {
                this.$message({
                    message: "删除成功",
                    type: "success"
                });
                setTimeout(function () {
                    location.reload();
                }, 1000);
            } else {
                this.$message.error(response.data.message);
            }
        },
        showAddCourseAd() {
            if (this.$refs.newCourseAd.$el.style.display == "none") {
                this.$refs.newCourseAd.$el.style.display = "block";
            } else {
                this.$refs.newCourseAd.$el.style.display = "none";
            }
        },
        jumpTo(url) {
            window.location.href = url;
        },
        openNewWindow(url) {
            window.open(url);
        }
    },
    mounted() {
        this.listCourseAdByCourseId();
    }
};
</script>
