import axios from "@/api/baseRequest.js";

//增加课程推广
export const apiAddCourseAd = (token, formData) =>
    axios.post("/api/1/admin/courseAd/addCourseAd", formData, {
        headers: {
            token: token
        }
    });

//删除课程的推广
export const apiDeleteCourseAdById = (formData) => axios.post("/api/1/admin/courseAd/deleteCourseAdById", formData);
